<template>
    <div>


        <div class="payment-block">

            <div class="hero" :style="{'background-image':`url('${mediaBase}${data.background}')`}">
                <div class="container">
                    <div class="header" v-html="translations.donation_page_title"></div>
                </div>
            </div>
            <div class="body-container ">
                <div class="container">
                    <div class="body">
                        <form @submit.prevent="paymentPersonalInfoForm">

                            <div class="row">
                                <div class="col-md-6 mb-3">

                                    <div class="amount-selection">
                                        <a class="amount-item" :class="{active: paymentAmount === 10}"
                                           @click.prevent="setAmount(10)" href="#">10</a>
                                        <a class="amount-item" :class="{active: paymentAmount === 20}"
                                           @click.prevent="setAmount(20)" href="#">20</a>
                                        <a class="amount-item" :class="{active: paymentAmount === 30}"
                                           @click.prevent="setAmount(30)" href="#">30</a>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <div class="input-group">
                                        <input type="number" step="1" min="1" class="form-control amount-input"
                                               v-model="paymentAmount" required>
                                        <div class="input-group-text">
                                            <img src="../../assets/img/azn-white.svg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-4" :class="{'text-danger':periodSelectionError}" v-if="1>2">
                                <div class="col-6">
                                    <label>
                                        <input type="radio" class="form-check-input" name="paymentSchedule"
                                               value="monthly" v-model="paymentType">
                                        {{translations.donation_monthly}}
                                    </label>
                                </div>
                                <div class="col-6">
                                    <label>
                                        <input type="radio" class="form-check-input" name="paymentSchedule"
                                               value="oneshot" v-model="paymentType">
                                        {{translations.donation_one_time}}
                                    </label>
                                </div>
                            </div>

                            <div class="mb-3">
                                <input type="text" step="1" min="1" class="form-control"
                                       :placeholder="translations.donation_name"
                                       v-model="paymentName" required autocomplete="name">
                            </div>

                            <div class="mb-3">
                                <input type="text" step="1" min="1" class="form-control"
                                       :placeholder="translations.donation_fin"
                                       v-model="paymentFin" required>
                            </div>

                            <div class="mb-3">
                                <input type="tel" step="1" min="1" class="form-control"
                                       :placeholder="translations.donation_phone"
                                       v-model="paymentPhone" required autocomplete="tel">
                            </div>
                            <div class="mb-3">
                                <input type="email" step="1" min="1" class="form-control"
                                       :placeholder="translations.donation_email"
                                       v-model="paymentEmail" required autocomplete="email">
                            </div>


                            <div class="col-md-12 my-4 small text-center">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" required value="1">
                                    <a class="d-inline-block ms-2" target="_blank" href="/shertler-ve-telebler">
                                        {{translations.donation_agree_terms}}
                                    </a>
                                </label>
                            </div>

                            <div class="alert alert-danger my-3" v-if="paymentError!==''">
                                {{paymentError}}
                            </div>

                            <div class="text-center " v-if="paymentLoading">
                                {{translations.donation_pls_wait}}
                            </div>
                            <div class="text-center " v-else>
                                <button class="button orange mb-2">{{translations.donation_button}}</button>
                                <br>
                                <span class="text-secondary">
                                {{translations.donation_next_step_payment}}
                                </span>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
    import {mediaBase} from '../../repositories/Repository';

    import {pay} from "../../repositories/GeneralDataRepository";
    import {mapState} from "vuex";

    export default {
        name: "DonatePage",
        components: {},
        props: ['data'],
        data() {
            return {
                mediaBase: mediaBase,
                paymentAmount: 20,
                paymentType: 'oneshot',
                paymentName: '',
                paymentFin: '',
                paymentPhone: '',
                paymentEmail: '',
                paymentLoading: false,
                paymentError: '',
                periodSelectionError: false
            }
        },
        computed:{
            ...mapState({
                translations: state=> state.app.translations
            }),
            languageCode() {
                return this.$route.params.language||'az';
            },
        },
        watch: {
            paymentType(newVal) {
                if (newVal !== '') {
                    this.periodSelectionError = false;
                }
            }
        },
        methods: {
            setAmount(amount) {
                this.paymentAmount = amount;
            },

            paymentPersonalInfoForm() {

                if (this.paymentType === '') {
                    this.periodSelectionError = true;
                } else {
                    this.periodSelectionError = false;
                    this.paymentLoading = true;
                    pay(this.paymentName, this.paymentFin, this.paymentEmail, this.paymentPhone, this.paymentType, this.paymentAmount, this.languageCode).then(data => {
                        this.requestInProgress = false;
                        if (data.code === 200) {

                            localStorage.setItem("paymentReferenceId", data.id);

                          localStorage.setItem("paymentReferenceId", data.id);

                          let url = data.url;
                          let post = data.data;


                          let minfo = {
                            "browserScreenHeight":  window.screen.height,
                            "browserScreenWidth": window.screen.width,
                            "browserTZ": new Date().getTimezoneOffset()
                          };

                          post['M_INFO'] = btoa(JSON.stringify(minfo));

                          let form = document.createElement('form');
                          form.setAttribute('method', 'post');
                          form.setAttribute('action', url);
                          //post data
                          for (let key in post) {
                            if (post.hasOwnProperty(key)) {
                              let hiddenField = document.createElement('input');
                              hiddenField.setAttribute('type', 'hidden');
                              hiddenField.setAttribute('name', key);
                              hiddenField.setAttribute('value', post[key]);
                              form.appendChild(hiddenField);
                            }
                          }

                          document.body.appendChild(form);
                          form.submit();
                          document.body.removeChild(form);


                        } else {
                            this.paymentError = data.error;

                            this.paymentLoading = false;
                        }
                    })
                }


            }
        },

        mounted() {
            this.paymentAmount = parseInt(this.$route.query['amount']) || 20;
        }

    }
</script>

<style lang="scss" scoped>


    .payment-block {


        .hero {

            background: url("../../assets/img/donate.png") center no-repeat;
            background-size: cover;
            padding-top: 350px;


            @media screen and (max-width: 500px) {
                padding-top: 150px;
            }


            .header {
                background: #DE7A2F;
                text-align: center;
                color: white;
                font-size: 40px;
                line-height: 1;
                font-weight: bold;
                padding: 20px;

                border-radius: 80px 80px 0 0;
                border: 5px solid #ffffff;
                overflow: hidden;
                position: relative;
                width: 100%;
                max-width: 600px;
                margin: 0 auto;


                @media (max-width: 576px) {
                    font-size: 24px;
                }

            }
        }

        .body-container {
            background: url("../../assets/img/logo-bg-tile.png");
            padding-bottom: 100px;

            .body {
                padding: 20px;
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);

                border-radius: 0 0 80px 80px;
                background: #ffffff;
                overflow: hidden;

                .amount-selection {
                    text-align: left;

                    .amount-item {
                        display: inline-block;
                        background: #BDBDBD;
                        color: white;
                        text-decoration: none;
                        padding: 5px 10px;
                        border-radius: 5px;
                        margin-right: 30px;
                        position: relative;
                        font-size: 20px;
                        line-height: 20px;
                        font-weight: bold;

                        &:after {
                            content: "";
                            position: absolute;
                            right: -12px;
                            top: 0;
                            width: 20px;
                            height: 15px;
                            background: url("../../assets/img/azn-gold.svg") center no-repeat;
                            background-size: contain;
                        }

                        &.active {
                            background: #0d6efd;
                        }
                    }
                }


                .payment-steps {
                    margin-top: 15px;
                    display: flex;
                    justify-content: space-between;

                    .step {
                        flex: 1;
                        text-align: center;
                        font-size: 13px;
                        color: #828282;

                        .order {
                            display: inline-block;
                            background: #BDBDBD;
                            color: white;
                            text-decoration: none;
                            padding: 5px 10px;
                            border-radius: 5px;
                            position: relative;
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: bold;


                        }

                        .title {
                            margin-top: 5px;
                            display: inline-block;
                            line-height: 13px !important;
                        }

                        &.active {
                            .order {
                                background: #0d6efd;
                            }
                        }

                    }
                }


                .text-thanks {
                    color: #DE7A2F;
                }

            }
        }
    }
</style>
