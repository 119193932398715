<template>

    <section class="info-blocks">

        <div class="container">

            <div class="title" v-if="data.title" v-html="data.title"></div>

            <div class="w-75 mx-auto">


                <div class="vacancy-item" v-for="(b,i) in data.vacancies" :key="i">
                    <div class="header" @click.prevent="toggleVacancy(i)">

                        <div class="row">
                            <div class="col-10">
                                <span class="lead">{{b.title}}</span> <br>
                                {{b.subtitle}}
                            </div>
                            <div class="col-2 d-flex align-items-center justify-content-end">
                                <i class="fa fa-chevron-down" v-if="openVacancies.indexOf(i)>-1"></i>
                                <i class="fa fa-chevron-right" v-else></i>
                            </div>
                        </div>
                    </div>
                    <div class="content" v-show="openVacancies.indexOf(i)>-1">

                        <div v-html="b.text"></div>

                        <div>

                            <form @submit.prevent="handleSubmit(i)">
                                <div class="row justify-content-center">

                                    <div class="col-md-6 mb-4">

                                        <label class="d-block">

                                            <input type="file" style="display: none"
                                                   @change="handleFile(i,'cv', $event.target.files[0])">
                                            <a class="button blue block small text-center">
                                                CV <span v-if="selectedFiles[i] && selectedFiles[i]['cv']">
                                            (<i class="fa fa-check"></i> Seçildi )</span>
                                            </a>
                                        </label>

                                    </div>

                                    <div class="col-md-6 mb-4">

                                        <label class="d-block">
                                            <input type="file" style="display: none"
                                                   @change="handleFile(i,'motivation', $event.target.files[0])">
                                            <a class="button blue block small text-center">
                                                Motivasiya məktubu <span
                                                    v-if="selectedFiles[i] && selectedFiles[i]['motivation']">
                                            (<i class="fa fa-check"></i> Seçildi) </span>
                                            </a>
                                        </label>

                                    </div>
                                    <div class="col-md-6 mb-4">
                                        <button type="submit" class="button orange block small text-center">
                                            Göndər
                                        </button>
                                    </div>

                                </div>

                            </form>


                        </div>

                    </div>
                </div>


            </div>
        </div>

    </section>

</template>

<script>

    import {applyVacancy} from "../../repositories/GeneralDataRepository";

    export default {
        name: "Vacancies",
        props: ["data"],
        data() {
            return {
                openVacancies: [],
                selectedFiles: {},
                progress: {}
            }
        },
        methods: {
            toggleVacancy(index) {
                if (this.openVacancies.indexOf(index) > -1) {
                    this.openVacancies.splice(this.openVacancies.indexOf(index), 1);
                } else {
                    this.openVacancies.push(index);
                }
            },
            handleSubmit(index) {

                if (this.selectedFiles[index] && this.selectedFiles[index]['cv'] && this.selectedFiles[index]['motivation']) {
                    applyVacancy(this.selectedFiles[index]['cv'], this.selectedFiles[index]['motivation'], event => {
                        this.progress = Math.round((100 * event.loaded) / event.total);
                    })
                        .then(response => {
                            this.message = response.data.message;
                            if (response.data.code === 200) {

                                alert('Müraciətiniz uğurla göndərildi')

                            } else {
                                alert(response.data.error);
                            }
                            this.selectedFiles[index] = {};
                        })
                        .catch((e) => {
                            console.log(e);
                            this.progress = 0;
                            alert("Could not upload the file! " + e);
                            this.currentFile = undefined;
                        });
                } else {
                    alert('Zəhmət olmasa CV və motivasiya məktubunu seçin')
                }

            },
            handleFile(index, type, file) {
                if (!this.selectedFiles[index]) {
                    this.selectedFiles[index] = {};
                }
                this.selectedFiles[index][type] = file;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-blocks {
        padding: 50px 0;

        .title {
            font-size: 40px;
            line-height: 50px;
            color: #00A0E9;
            text-align: center;
            margin-bottom: 50px;

            span {
                color: #DE7A2F !important;
            }

            @media (max-width: 990px) {
                font-size: 24px;
            }
        }


        .vacancy-item {
            border-bottom: 1px solid #bababa;
            padding: 15px 25px;
            background: #F0F0F0;
            color: #828282;


            .lead {
                font-weight: bold;
                color: #000000;
            }

            .content {
                margin-top: 20px;
                color: #828282 !important;
            }
        }


    }

    .w-75 {

        @media (max-width: 576px) {
            width: 100% !important;
        }
    }
</style>